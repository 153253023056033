<template>
  <section class="loginInterview">
    <div class="loginInterview__body">
      <div class="loginInterview__container">
        <art dir="top" />
        <section class="loginInterview__inner">
          <h2 class="loginInterview__title third-font no-ttransform">Models 1A</h2>
          <p class="loginInterview__msg">Para acceder a la entrevista, por favor ingrese su Email</p>
          <form class="loginInterview__form">
            <label class="loginInterview__label">Email</label>
            <input class="loginInterview__input" type="email" placeholder="" v-model="email" />
            <button class="loginInterview__btn" @click.prevent="toInterview" >Ingresar</button>
          </form>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import art from "@/components/art/art.vue";
import api from "@/api/index";
import Swal from "sweetalert2";

export default {
  components: { art },
  data: function () {
    return {
      email: "",
    };
  },
  methods: {
    async toInterview() {
      const { data } = await api.get(`${this.$host}interviews/hasinterview?email=${this.email.trim()}`);
      if (!data.error && data.model?.availableInterview) {
        Swal.fire({
          title: "Enviando",
          text: "Sus datos están siendo enviados",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await this.$router.replace({ name: "interview", params: { model: data.model } });
        Swal.close();
        return false;
      }
      Swal.fire({
        text: "No tiene permiso para esta acción, por favor, comuníquese con el administrador de la sede.",
        icon: "error",
        confirmButtonText: "Aceptar",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
  },
};
</script>

<style lang="scss">
.loginInterview {
  @include Flex(row);
  height: 100vh;
  margin: auto;
  font-family: $first_font;
  font-size: 16px;
  text-align: center;
  background-color: $white_smoke;
  input,
  button {
    font-family: $first_font;
    font-size: 16px;
  }
  &__body {
    max-width: 360px;
    padding: 15px;
    background-color: $white_smoke;
  }
  &__container {
    height: 90vh;
    max-height: 650px;
    min-width: 330px;
    padding: $mpadding;
    @include Flex(row);
    position: relative;
    background: #fff;
    border-radius: $mradius/2;
    box-shadow: $dshadow;
    overflow: hidden;
  }
  &__inner {
    @include Flex(column);
    max-width: 500px;
    padding: $mpadding * 2;
    margin: $mpadding * 2 auto;
    z-index: 2;
  }

  &__title {
    color: $primary_color;
    font-size: $title-mobile;
  }
  &__msg {
    margin: $mpadding * 1 auto;
    font-size: 1em;
  }
  &__input {
    width: 100%;
    padding: 8px 0;
    border: none;
    border-bottom: 1px solid $silver;
    color: $chicago;
  }
  &__btn {
    appearance: none;
    @include ButtonStyle();
    background-color: $primary_color;
    width: 100%;
    height: 36px;
    margin: 30px auto;
    border: 0;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 2pc;
    text-transform: uppercase;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        opacity: 0.5;
      }
    }
    &:hover {
      text-decoration: none;
      color: $white;
      opacity: 0.7;
    }
  }
  &__form {
    @include Flex(column, flex-start, flex-start);
    max-width: 200px;
    margin: 15px auto;
  }
  @media (min-width: $tablet_width) {
    &-body {
      padding: $mpadding;
    }
  }
  @media (min-width: $desktop_width) {
    &__title {
      font-size: $title-desktop;
    }
  }
}
</style>
